import { BrowserRouter, Routes, Route } from 'react-router-dom'

import FaixaHeader from '../Components/FaixaHeader'
import Cabecalho from '../Components/Cabecalho'
import Rodape from '../Components/Rodape'

import Inicio from '../Inicio'
import QSomos from '../qSomos'
import Login from '../Login'
import LoginAcampados from '../Login/LoginAcampados'
import CadastroAcampados from '../CadastroAcampados'
import Manutencao from '../Manutencao'

export default function NaoAutenticado() {
    return (
        <BrowserRouter>
            <FaixaHeader />
            <Cabecalho />
            <Routes>
                <Route path='/' element={<Inicio />} />
                <Route path='/QSomos' element={<QSomos />} />
                <Route path='/Login' element={<Login />} />
                <Route path='/LoginAcampados' element={<LoginAcampados />} />
                <Route path='/CadastroAcampados' element={<CadastroAcampados />} />
                <Route path='/Manutencao' element={<Manutencao />} />

                <Route path='*' element={<Inicio />} />
            </Routes>
            <Rodape />
        </BrowserRouter>
    )
}