import React, { useState, useContext } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'
import apiLocal from '../apiLocal/apiLocal'
import './cadastroAcampadosConjuge.estilo.scss'
import { useNavigate } from 'react-router-dom'
import { IMaskInput } from 'react-imask'
import { cpf } from 'cpf-cnpj-validator'
import { toast } from 'react-toastify'

export default function CadastroAcampadosConjuge() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()
    const navegar = useNavigate()

    const [nomeMask, setNomeMask] = useState('')
    const [rgMask, setRgMask] = useState('')
    const [cpfMask, setCpfMask] = useState('')
    const [nisMask, setNisMask] = useState('')
    const [cadunicoMask, setCadUnicoMask] = useState('')
    const [tituloeleitorMask, setTituloEleitorMask] = useState('')
    const [celularMask, setCelularMask] = useState('')

    async function cadastrarAcampadoConjuge(e) {
        const idtitular = localStorage.getItem('@Id')
        const titularId = JSON.parse(idtitular)
        const tokenId = localStorage.getItem('@Token')
        const token = JSON.parse(tokenId)

        try {
            e.preventDefault()
            if (!nomeMask || !rgMask || !cpfMask || !nisMask || !cadunicoMask || !tituloeleitorMask || !celularMask) {
                toast.warning('Existem Campos Em branco', {
                    toastId: 'ToastID'
                })
                return
            }

            const cpfValido = cpf.isValid(cpfMask)
           // let regex = /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[a-z]){1})(?=(?:.*?[0-9]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/
            if (!cpfValido) {
                toast.error('CPF Inválido', {
                    toastId: 'ToastID'
                })
                return
            }

            let nome = nomeMask.toLocaleLowerCase()
            let rg = rgMask.match(/\d/g).join("")
            let cpfV = cpfMask.match(/\d/g).join("")
            let nis = nisMask.match(/\d/g).join("")
            let cadunico = cadunicoMask.match(/\d/g).join("")
            let tituloeleitor = tituloeleitorMask.match(/\d/g).join("")
            let celular = celularMask.match(/\d/g).join("")

            const resposta = await apiLocal.post('/CriarAcampadosConjuge', {
                nome,
                rg,
                cpfV,
                nis,
                cadunico,
                tituloeleitor,
                celular,
                titularId
            },{
                headers:{
                    // eslint-disable-next-line
                    Authorization: 'Bearer ' + `${token}`
                }
            })
            toast.success(resposta.data.dados, {
                toastId: 'ToastId'
            })
            navegar('/')

        } catch (err) {
            toast.error('Erro ao Cadastrar', {
                toastId: 'ToastId'
            })
        }
    }

    return (
        <div className='conteinerGeralCadastroAcampadosConjuge'>
            <h1>Cadastro Acampado Conjuge</h1><br />
            <form onSubmit={cadastrarAcampadoConjuge}>
                <input
                    type='text'
                    placeholder='Nome do Conjuge*'
                    value={nomeMask}
                    onChange={(e) => setNomeMask(e.target.value)}
                />
                <input
                    type='text'
                    placeholder='RG*'
                    value={rgMask}
                    onChange={(e) => setRgMask(e.target.value)}
                />
                <IMaskInput
                    type="text"
                    mask='000.000.000-00'
                    placeholder='CPF*'
                    value={cpfMask}
                    onChange={(e) => setCpfMask(e.target.value)}
                />               
                <input
                    type='text'
                    placeholder='NIS*'
                    value={nisMask}
                    onChange={(e) => setNisMask(e.target.value)}
                />
                <input
                    type='text'
                    placeholder='Cadastro Único*'
                    value={cadunicoMask}
                    onChange={(e) => setCadUnicoMask(e.target.value)}
                />
                <input
                    type='text'
                    placeholder='Titulo de Eleitor*'
                    value={tituloeleitorMask}
                    onChange={(e) => setTituloEleitorMask(e.target.value)}
                />
                <IMaskInput
                    type='text'
                    mask='(00) 00000-0000'
                    placeholder='Celular(com WhatsApp)*'
                    value={celularMask}
                    onChange={(e) => setCelularMask(e.target.value)}
                />
                <button type='submit'>Enviar</button>
                <p>Campos com * são obrigatórios</p>
                <p>Caso não tenha o Cad Unico inserir o numero do NIS no lugar<br /></p>
            </form>
        </div>
    )
}