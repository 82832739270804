import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import apiLocal from '../../../apiLocal/apiLocal'
import './dadosAcampadoAtivo.estilo.scss'
import NAutorizado from '../../../imagens/nautorizado.png'

import { IMaskInput } from 'react-imask'



export default function AcampadosAtivos() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [acampadosAtivos, setAcampadosAtivos] = useState([''])

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')
    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
    }, [])


    useEffect(() => {
        try {
            async function listarAcampados() {
                const resposta = await apiLocal.get('/ListarAcampadosAtivos', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setAcampadosAtivos(resposta.data)
            }
            listarAcampados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [acampadosAtivos])

    return (
        <div>
            <div className='containerGeralDadosAcampadosAtivo'>
                {coordenador === false || (grupo !== 'ADM' && grupo !== 'ROOT') ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div>
                        <div className='tituloDadosAcampadosAtivo'>
                            <h1>Acampados Ativos</h1>
                        </div>
                        <div className='tabelaDadosAcampadoAtivo'>
                            <table className='dadosTabelas'>
                                <thead>
                                    <tr key="">
                                        <th>Nome Titular</th>
                                        <th>CPF Titular</th>
                                        <th>Rancho</th>
                                        <th>Incra</th>
                                    </tr>
                                    {acampadosAtivos.map((item) => {
                                        return (
                                            <tr key="">
                                                <>
                                                    <td>{item.nome}</td>
                                                    <td>
                                                        <IMaskInput
                                                            disabled
                                                            mask='000.xxx.xxx-00'
                                                            value={item.cpf}
                                                        />
                                                    </td>
                                                    <td>{item.numeroRancho}</td>
                                                    <td>{item.incra}</td>
                                                </>
                                            </tr>
                                        )
                                    })}
                                </thead>

                            </table>

                        </div>
                    </div>
                }
            </div>
        </div>
    )
}