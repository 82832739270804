import React, { useContext, useState } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'
import apiLocal from '../apiLocal/apiLocal'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import './cadastroAcampadosFilhos.estilo.scss'


export default function CadastroAcampadosFilhos() {
    const { verificaToken } = useContext(AuthContexts)
    verificaToken()
    const navegar = useNavigate()

    const [nomeMask, setNomeMask] = useState('')

    async function cadastrarAcampadoFilho(e) {
        try {
            e.preventDefault()
            const idtitular = localStorage.getItem('@Id')
            const paisId = JSON.parse(idtitular)
            const tokenId = localStorage.getItem('@Token')
            const token = JSON.parse(tokenId)
            let nome = nomeMask.toLocaleLowerCase()
            const resposta = await apiLocal.post('/CriarFilhosMenores', {
                nome,
                paisId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success(resposta.data.dados, {
                toastId: 'ToastId'
            })
            navegar('/')
        } catch (err) {
            toast.error('Erro ao Cadastrar', {
                toastId: 'ToastId'
            })
        }

    }

    return (
        <div className='conteinerGeralCadastroAcampadosFilhos'>
            <h1>Cadastrar Filhos</h1>
            <form onSubmit={cadastrarAcampadoFilho}>
                <input
                    type="text"
                    placeholder='Digite o Nome do Filho'
                    value={nomeMask}
                    onChange={(e) => setNomeMask(e.target.value)}
                />
                <button type='submit'>Enviar</button>
            </form>
        </div>
    )
}