import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'
import apiLocal from '../apiLocal/apiLocal'
import { useNavigate } from 'react-router-dom'
import './visualizarDadosAcampado.estilo.scss'


import { IMaskInput } from 'react-imask'
import { toast } from 'react-toastify'


export default function VisualizarDadosAcampado() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)
    const iId = localStorage.getItem('@Id')
    const id = JSON.parse(iId)
    const mudarTela = useNavigate()


    const [dados, setDados] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        try {
            async function listarAcampados() {
                const resposta = await apiLocal.post('/ListarAcampadosAtivar', {
                    id
                }, {
                    headers: {
                        // eslint-disable-next-line
                        Authorization: `Bearer ${token}`
                    }
                })
                setDados(resposta.data)
            }
            listarAcampados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    async function alterarSenha(e){
        try {
            e.preventDefault()
            if(!password){
                toast.warning('Existem Campos Em branco', {
                    toastId: 'ToastID'
                })
                return
            }
            let regex = /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[a-z]){1})(?=(?:.*?[0-9]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/
            if (!regex.exec(password)) {
                toast.warning('A senha deve conter no mínimo 1 caractere em maiúsculo, 1 caractere em minusculo, 1 número e 1 caractere especial!', {
                    toastId: 'ToastID'
                })
                return
            }

            const cpfV = dados.cpf
            await apiLocal.put('/AlterarSenhaTitular', {
                cpfV,
                password
            },{
                headers: {
                    // eslint-disable-next-line
                    Authorization: `Bearer ${token}`
                }                
            })
            toast.success('Senha Alterada Com Sucesso', {
                toastId: 'ToastId'
            })
            mudarTela('/')
            localStorage.clear('')
            window.location.reload()
        } catch (err) {
            
        }
    }

    if (dados.acampadosEC === undefined) {
        <div>
            <h1>Carregando</h1>
        </div>
    } else {
        return (
            <div>
                <div className='conteinerVisualizarDadosAcampado'>
                    <div className='tituloVisualizarDadosAcampado'>
                        <h1>Visualizar Dados Acampado</h1>
                    </div>
                    <div>
                        {(dados.acampadosEC.nome === 'União Estável' || dados.acampadosEC.nome === 'Casado') && dados.conjuge === null ?
                            <h2>Os Dados do Conjuge Não Foram Preenchidos</h2>
                            :
                            <div>
                                <div className='blocosVisualizarDadosAcampado'>
                                    <div className='blocosTitular'>
                                        <h1>Titular</h1>
                                        <span>Nome: {dados.nome}</span>
                                        <span>
                                            CPF: <IMaskInput
                                                disabled
                                                type="text"
                                                mask='000.000.000-00'
                                                value={dados.cpf} />
                                        </span>
                                        <span>RG: {dados.rg}</span>
                                        <span>NIS: {dados.nis}</span>
                                        <span>Cad. Único: {dados.cadunico}</span>
                                        <span>Titulo Eleitor: {dados.tituloeleitor}</span>
                                        <span>
                                            Celular: <IMaskInput
                                                disabled
                                                type="text"
                                                mask='(00) 00000-0000'
                                                value={dados.celular}
                                            />
                                        </span>
                                    </div>
                                    {dados.conjuge != null && (
                                        <div className='blocosTitular'>
                                            <h1>Conjuge</h1>
                                            <span>Nome: {dados.conjuge.nome}</span>
                                            <span>
                                                CPF: <IMaskInput
                                                    disabled
                                                    type="text"
                                                    mask='000.000.000-00'
                                                    value={dados.conjuge.cpf} />
                                            </span>
                                            <span>RG: {dados.conjuge.rg}</span>
                                            <span>NIS: {dados.conjuge.nis}</span>
                                            <span>Cad. Único: {dados.cadunico}</span>
                                            <span>Titulo Eleitor: {dados.conjuge.tituloeleitor}</span>
                                            <span>
                                                Celular: <IMaskInput
                                                    disabled
                                                    type="text"
                                                    mask='(00) 00000-0000'
                                                    value={dados.conjuge.celular}
                                                />
                                            </span>
                                        </div>
                                    )}
                                </div>

                            </div>
                        }
                    </div>
                    <h2>Altere a senha somente se for necessário</h2>
                    <form onSubmit={alterarSenha}>
                        <input
                            type="password"
                            placeholder='Digite a Nova Senha'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button type='submit'>Enviar Para Alterar a Senha</button>
                    </form>
                </div>
            </div>
        )
    }
}


