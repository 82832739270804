import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import apiLocal from '../../apiLocal/apiLocal'
import NAutorizado from '../../imagens/nautorizado.png'
import './root.estilo.scss'


export default function RootAdm(){

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])



    return(
        <div>
            <div className='conteinerRootGeral'>
            {coordenador === false || (grupo !== 'ROOT') ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div>
                        <h1>Pagina Root</h1>
                    </div>
                }
            </div>
        </div>
    )
}