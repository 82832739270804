import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import { useParams, useNavigate } from 'react-router-dom'
import apiLocal from '../../../apiLocal/apiLocal'
import './conferirDadosAcampado.estilo.scss'
import NAutorizado from '../../../imagens/nautorizado.png'

import { IMaskInput } from 'react-imask'
import { toast } from 'react-toastify'

export default function ConferirAcampado() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const mudarTela = useNavigate()

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    const { id } = useParams()
    const [dados, setDados] = useState('')

    const [numeroRancho, setSetNumeroRancho] = useState('')
    //const [status, setStatus] = useState('')

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        try {
            async function listarAcampados() {
                const resposta = await apiLocal.post('/ListarAcampadosAtivar', {
                    id
                }, {
                    headers: {
                        // eslint-disable-next-line
                        Authorization: `Bearer ${token}`
                    }
                })
                setDados(resposta.data)
            }
            listarAcampados()
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [])

    async function ativarAcampado(e) {
        e.preventDefault()
        if (!numeroRancho ) {
            toast.warning('Informar Rancho')
            return
        }
        try {
            await apiLocal.put('/AtivarAcampado', {
                id,
                numeroRancho,
                //status
            }, {
                headers: {
                    // eslint-disable-next-line
                    Authorization: `Bearer ${token}`
                }
            })
            mudarTela('/DashboardCoordenadores')
        } catch (err) {
            toast.error(err.response.data.error)
        }
    }

    if (dados.acampadosEC === undefined) {
        return (
            <div><h1>Carregando</h1></div>
        )
    } else {
        return (
            <div>
                <div className='containerGeralConferiDadosAcampados'>
                    {coordenador === false || (grupo !== 'ADM' && grupo !== 'ROOT') ?
                        <img src={NAutorizado} alt="" />
                        :
                        <>
                            {(dados.acampadosEC.nome === 'União Estável' || dados.acampadosEC.nome === 'Casado') && dados.conjuge === null ?
                                <h2>Os Dados do Conjuge Não Foram Preenchidos</h2>
                                :
                                <div>
                                    <div className='tituloConferirDadosAcampados'>
                                        <h1>Conferência de Acampados</h1>
                                    </div>
                                    <div className='blocosAtivarDadosAcampados'>
                                        <div className='blocosTitular'>
                                            <h1>Titular</h1>
                                            <span>Nome: {dados.nome}</span>
                                            <span>
                                                CPF: <IMaskInput
                                                    disabled
                                                    type="text"
                                                    mask='000.000.000-00'
                                                    value={dados.cpf} />
                                            </span>
                                            <span>RG: {dados.rg}</span>
                                            <span>NIS: {dados.nis}</span>
                                            <span>Cad. Único: {dados.cadunico}</span>
                                            <span>Titulo Eleitor: {dados.tituloeleitor}</span>
                                            <span>
                                                Celular: <IMaskInput
                                                    disabled
                                                    type="text"
                                                    mask='(00) 00000-0000'
                                                    value={dados.celular}
                                                />
                                            </span>
                                        </div>
                                        {dados.conjuge != null && (
                                            <div className='blocosTitular'>
                                                <h1>Conjuge</h1>
                                                <span>Nome: {dados.conjuge.nome}</span>
                                                <span>
                                                    CPF: <IMaskInput
                                                        disabled
                                                        type="text"
                                                        mask='000.000.000-00'
                                                        value={dados.conjuge.cpf} />
                                                </span>
                                                <span>RG: {dados.conjuge.rg}</span>
                                                <span>NIS: {dados.conjuge.nis}</span>
                                                <span>Cad. Único: {dados.cadunico}</span>
                                                <span>Titulo Eleitor: {dados.conjuge.tituloeleitor}</span>
                                                <span>
                                                    Celular: <IMaskInput
                                                        disabled
                                                        type="text"
                                                        mask='(00) 00000-0000'
                                                        value={dados.conjuge.celular}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                        {dados.filhosMenores > 0 && (
                                            <div className='blocosTitular'>
                                                <h1>Filhos</h1>
                                            </div>
                                        )}
                                    </div>
                                    <form onSubmit={ativarAcampado}>
                                        <input
                                            type="number"
                                            placeholder='Digite o Numero do Rancho'
                                            value={numeroRancho}
                                            onChange={(e) => setSetNumeroRancho(e.target.value)}
                                        />
                                        {/*<select
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option></option>
                                            <option>Ativo</option>
                                            <option>Inativo</option>
                                        </select>*/}
                                        <button>Enviar</button>
                                    </form>
                                </div>
                            }
                        </>
                    }
                    <button className='buttonSair' onClick={() => mudarTela('/DashboardCoordenadores')}>Sair</button>
                </div>
            </div>
        )
    }
}