import React, { useContext } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'
import './inicio.estilo.scss'

import Bandeira from '../imagens/BANDEIRA_MNL.png'


export default function Inicio() {
    const {verificaToken} = useContext(AuthContexts)
    verificaToken()    
    return (
        <div className='conteinerInicioGeral'>
            <img src={Bandeira} alt='Bandeira MNL' />
        </div>
    )
}