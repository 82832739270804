import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { IMaskInput } from 'react-imask'
import { toast } from 'react-toastify'

import './loginAcampados.estilo.scss'

export default function LoginAcampados() {

    
    const {loginAcampados, verificaToken} = useContext(AuthContexts)
    verificaToken()

    const [cpfMask, setCpfMask] = useState('')
    const [password, setPassword] = useState('')

    async function logarAcampados(e) {
        e.preventDefault()
        try {
            if (!cpfMask || !password) {
                toast.error('Preencha todos os campos', {
                    toastId: 'ToastID'
                })
                return
            }

            let cpf = cpfMask.match(/\d/g).join("")
            await loginAcampados(cpf, password)           
        } catch (err) {
            toast.error('Erro ao Conectar no Servidor', {
                toastId: 'ToastId'
            })
        }
    }
    return (
        <div className='conteinerLoginAcampadosGeral'>
            <h1>Login Acampados</h1>
            <form onSubmit={logarAcampados}>
                <IMaskInput
                    type='text'
                    mask='000.000.000-00'
                    placeholder='Seu CPF é o Login para entrar'
                    value={cpfMask}
                    onChange={(e) => setCpfMask(e.target.value)}
                />
                <input
                    type='password'
                    placeholder='Digite sua Senha'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button>Enviar</button>
            </form>
            <p>
                Para solicitar cadastro de interesse em Reforma Agrária
                <Link to='/CadastroAcampados'>Clique Aqui</Link>
            </p>
        </div>
    )
}